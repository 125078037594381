import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useFetcher } from '../utils/fetch'
import { useToast } from 'vuestic-ui'

interface AuthBody {
  email: string
  password: string
}

interface LoginResponse {
  access_token: string
  payload: {
    sub: string
    email: string
    firstName: string
    lastName: string
    claims: string[]
    roles: string[]
  }
}

const AUTH_KEY_LOCALSTORAGE = 'authToken'

export const useAuth = defineStore('auth', () => {
  // const IsLogued = ref<boolean>(false)
  const Token = ref<string | null>(null)

  const IsLogued = async () => {
    const { error } = await useFetcher().Fetch('identity/auth/verify_jwt', {
      method: 'POST',
    })
    if (error) {
      return false
    }
    return true
  }

  const Login = async (authBody: AuthBody) => {
    const { error, data } = await useFetcher().Fetch<LoginResponse>('identity/auth/panel_signin', {
      method: 'POST',
      body: authBody,
    })

    const { init: notify } = useToast()
    if (error) {
      notify({
        title: 'Error',
        message: error?.message ?? 'unknown error',
      })
      return false
    } else {
      // IsLogued.value = true
      Token.value = data.access_token
      localStorage.setItem(AUTH_KEY_LOCALSTORAGE, data.access_token)
      return true
    }
  }

  const Logout = () => {
    // IsLogued.value = false
    Token.value = null
    localStorage.removeItem(AUTH_KEY_LOCALSTORAGE)
  }

  const loadData = () => {
    Token.value = localStorage.getItem(AUTH_KEY_LOCALSTORAGE)
    // if (Token.value) {
    //   // TODO: see if is a valid token
    //   // IsLogued.value = true
    // }
  }

  loadData()

  return {
    Login,
    Logout,
    IsLogued,
    Token,
  }
})
