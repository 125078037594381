<template>
  <div class="w-full flex place-items-center gap-1 p-2 relative overflow-x-auto">
    <TransitionGroup name="tabtransition" @beforeLeave="beforeLeave">
      <div
        key="/dashboard"
        class="px-2 py-1 bg-[var(--va-background-secondary)] rounded-md inline-flex place-items-center shadow-lg font-bold cursor-pointer transition-all duration-500 whitespace-nowrap select-none"
        :class="'/dashboard' === route.path ? ['selected-item'] : []"
        @click="router.push({ path: '/dashboard' })"
      >
        <span> Dashboard </span>
      </div>
      <div
        v-for="(tab, index) in tabs"
        :key="tab.path"
        :to="{ path: tab.path }"
        class="block px-2 bg-[var(--va-background-secondary)] dark:black rounded-md inline-flex place-items-center shadow-lg font-bold h-full cursor-pointer transition-all duration-500 whitespace-nowrap select-none"
        :class="tab.path === route.path ? ['selected-item'] : []"
        @click="router.push({ path: tab.path })"
      >
        <span>{{ tab.name }}</span>
        <CoolButton class="clean mini" after-icon="close" @click.stop="closeTab(index)" />
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import CoolButton from '../dara-components/CoolButton.vue'
import { useRoute, useRouter } from 'vue-router'
import { useGlobalStore } from '../../stores/global-store'
import { storeToRefs } from 'pinia'
// import { useColors } from 'vuestic-ui'

const route = useRoute()
const router = useRouter()
const { tabs } = storeToRefs(useGlobalStore())

// const { currentPresetName } = useColors()

const closeTab = (tabIndex: number) => {
  const [removedTab] = tabs.value.splice(tabIndex, 1)

  if (route.path === removedTab.path) {
    router.push({
      path: tabs.value.length === 0 ? '/dashboard' : tabs.value[tabs.value.length - 1].path,
    })
  }
}

const beforeLeave = (ele: Element): void => {
  const el = ele as HTMLElement
  const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)

  el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`
  el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`
  el.style.width = width
  el.style.height = height
}
</script>

<style scoped>
.selected-item {
  --selected-shadow-color: rgb(from var(--va-primary) r g b / 0.5);
  @apply shadow-[--selected-shadow-color] text-[var(--va-primary)];
}

.tabtransition-move, /* apply transition to moving elements */
.tabtransition-enter-active,
.tabtransition-leave-active {
  transition: all 0.3s ease;
}

.tabtransition-enter-from {
  opacity: 0;
  transform: translateX(200px) scaleX(2);
}
.tabtransition-enter-to {
  opacity: 1;
  transform: translateX(0px);
}

.tabtransition-leave-from {
  opacity: 1;
}
.tabtransition-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.tabtransition-leave-active {
  position: absolute;
}
</style>
